<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-meeting-room">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div v-if="visitorState === 'offline'" slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/meeting.png" alt="login" class="mx-auto" style="max-width: 400px" />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">
                <div class="vx-card__title m-20">
                  <h4 class="mb-4">{{ $t('vue.visitorOffline') }}</h4>
                  <p>{{ $t('vue.visitorOfflineMessage') }} {{ timeout / 1000 }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visitorState: 'online',
      timeout: 0
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  created() {
    this.$vs.loading({ type: 'point' })
  },
  watch: {
    visitorState() {
      if (this.visitorState === 'offline') {
        this.timeout = 10000
        setTimeout(() => {
          location.href = '/visitors'
        }, this.timeout)
      }
    }
  },
  async mounted() {
    const vm = this
    const type = this.$route.query.type
    const name = this.$route.query.name
    const visitorId = this.$route.query.id
    const email = this.$route.query.email
    const url = this.$route.query.url
    const agentId = this.$route.query.agentId
    const campaignId = this.$route.query.campaignId
    const surflySetting = this.$route.query.surflySetting
    const dialogId = this.$route.query.dialogId
    const abTestId = this.$route.query.abTestId

    setInterval(() => {
      if (vm.timeout > 0) {
        vm.timeout = vm.timeout - 1000
      }
    }, 1000)

    if (visitorId && type) {
      const visitorRef = vm.$database.ref(`status/${visitorId}`)
      visitorRef.once('value').then(async (snapshot) => {
        const val = snapshot.val()

        if (val && val.state) {
          vm.$vs.loading.close()
          vm.visitorState = val.state
          if (val.state === 'online' && type === 'surfly-agent') {
            const joinSurfly = vm.$functions.httpsCallable('joinSurfly')
            const data = {
              agentName: name,
              agentEmail: email,
              visitorId,
              locale: navigator.language.slice(0, 2) === 'de' ? 'de' : 'en'
            }
            if (url) {
              data.url = url
            }
            if (agentId) {
              data.agentId = agentId
            }
            if (campaignId) {
              data.campaignId = campaignId
            }
            if (surflySetting) {
              data.surflySetting = surflySetting
            }
            if (dialogId && dialogId.length > 0) {
              data.dialogId = dialogId
            }
            if (abTestId && abTestId.length > 0) {
              data.abTestId = abTestId
            }
            const response = await joinSurfly(data)
            location.href = `${response.data.surflyUrl}?name=${name}&email=${email}`
          }
        }
      })
    }
  }
}
</script>
